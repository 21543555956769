import React from 'react';
import Page404 from '../components/errors/404';

function Error404() {
    return (
        <>
            <Page404 />
        </>
    )
}

export default Error404